<template>
  <div>
    <div class="wrapper">
      <div v-if="activeTab === 'detail'">
        <GridView
          :pics="pics"
          :cols="1"
          style="max-width:150vh; margin:auto;"
          ref="detail"
        />
      </div>
      <div v-if="activeTab === 'grid'" style="max-width:300vh; margin:auto;">
        <GridView
          :pics="picsWithoutCover"
          :cover="cover"
          :cols="2"
          @clickImg="clickImg"
        />
      </div>
      <div v-if="activeTab === 'flipbook'">
        <FlipView :pages="pics" :pageNum="0" />
      </div>
      <div v-if="activeTab === 'about'">
        <AboutComponent />
      </div>
    </div>
    <div class="bottom-navbar">
      <button
        v-for="nav in navItems"
        :key="nav.id"
        @click="setTab(nav.id)"
        :class="{
          hiddenMobile: nav.hiddenMobile,
          active: nav.id === activeTab,
        }"
      >
        <img v-if="nav.icon" :src="'./' + nav.icon" :alt="nav.label" />
        <!-- 
        {{ nav.label }} -->
      </button>
    </div>

    <!--  
    <div class="bottom-navbar">
      <button @click="setTab('detail')">Single Col</button>
      <button @click="setTab('grid')">Two Cols</button>
      <button @click="setTab('flipbook')">Flipbook</button>
      <button @click="setTab('about')">About</button>
    </div>
    -->
  </div>
</template>

<script>
import GridView from "./GridView.vue";
import FlipView from "./FlipView.vue";
import AboutComponent from "./About.vue";

var pics = [null]; //Define array pictures

const FOLDER = "book/e"; // "book/f1/e";
const START = 2;
const END = 57;
//loop through all pictures
pics.push("book/cover.png");
for (var i = START; i <= END; i++) {
  pics.push(FOLDER + "/" + i + ".jpg"); //Add to array
  //pics.push("book/e/page" + i + ".jpg"); //Add to array
}

export default {
  components: {
    GridView,
    FlipView,
    AboutComponent,
  },
  data() {
    return {
      activeTab: "grid",
      pics: pics,
    };
  },
  //currentColor

  // icons: https://fonts.google.com/icons?selected=Material+Symbols+Outlined:view_agenda:FILL@0;wght@400;GRAD@0;opsz@24&icon.query=view&icon.size=24&icon.color=%23e8eaed
  computed: {
    navItems() {
      return [
        {
          id: "grid",
          label: "Two Cols",

          cols: 2,
          icon: "icons/grid.svg",
        },
        {
          id: "detail",
          label: "Single Col",

          cols: 1,
          icon: "icons/row.svg",
        },

        {
          id: "flipbook",
          label: "Flipbook",

          icon: "icons/book2.svg",
          hiddenMobile: true,
        },
        {
          id: "about",
          label: "About",

          icon: "icons/info.svg",
        },
      ];
    },

    picsWithoutCover() {
      return pics.slice(2, pics.length);
    },
    cover() {
      return pics.slice(1, 2);
    },
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab;
      // window.location.hash = tab; // Update the URL hash for refresh
      //replace hash but dont push to history
      window.location.replace("#" + tab);
    },
    clickImg(index) {
      console.log("Image clicked", index);
      this.setTab("detail");
      //scroll to image index smoothly
      var imgRefId = "img" + index;

      this.$nextTick(() => {
        this.scrollToImage(index);
      });
      // also delay 400ms
      setTimeout(() => {
        this.scrollToImage(index);
        /*  var el = document.getElementById(imgRefId);
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }*/
      }, 300);

      /* this.$nextTick(() => {
        this.scrollToImage(index);
      });*/
      /* 
      this.activeTab = "flipbook";
      this.$nextTick(() => {
        this.$refs.flipview.$emit("update:pageNum", index);
      });*/
    },
    scrollToImage(index) {
      const gridView = this.$refs["detail"]; // Access the GridView component
      if (gridView) {
        const imgRef = gridView.$refs["img" + index]; // Access the image ref inside GridView
        if (imgRef && imgRef[0]) {
          imgRef[0].scrollIntoView({
            // behavior: "smooth",
            block: "center",
          });
        }
      }
    },
  },
  mounted() {
    var hash = window.location.hash.replace("#", "");
    if (
      hash == "detail" ||
      hash == "grid" ||
      hash == "flipbook" ||
      hash == "about"
    ) {
      this.activeTab = hash;
    }
    // if hash is a number, go to the flipbook
    if (hash && !isNaN(hash)) {
      this.activeTab = "flipbook";
      this.$nextTick(() => {
        // this.$refs.flipview.$emit("update:pageNum", hash);
      });
    }
    //this.activeTab = || "detail";
  },
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;

  background-color: #ccc;
  background: #bfbfc0;
  min-height: 100%;
}
.wrapper {
  padding: 1vw;
}
@media (max-width: 800px) {
  .wrapper {
    padding: 1px;
  }
}
.bottom-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background: transparent;
  background: rgb(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  padding: 10px 0;
  border-top: 1px solid #ccc;
  padding: 3px;
  border: 0;
}

.bottom-navbar button {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 0;
  margin: 3px;
  background: rgb(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 5px;
}

.bottom-navbar button.active {
  color: red;
  background-color: white;
  background: rgb(255, 255, 255, 0.85);
}
.bottom-navbar button.active img {
  fill: currentColor; /* Use currentColor for SVG fill */
  stroke: currentColor; /* Use currentColor for SVG stroke, if needed */
  fill: red;
  stroke: blue;
}

@media (max-width: 768px) {
  .hiddenMobile {
    display: none;
  }
}
</style>
