<template>
  <div>
    <div class="header">
      <div
        class="image-wrapper"
        style="max-width:80vh; margin:auto; margin-bottom:20px;"
      >
        <img :src="cover" alt="" v-if="cover" loading="lazy" />
      </div>
    </div>

    <div :class="layoutClass" style="padding-bottom:30vh;  ">
      <div
        v-for="(pic, index) in pics"
        :key="index"
        class="image-wrapper"
        :ref="'img' + index"
      >
        <img
          :src="pic"
          alt=""
          v-if="pic"
          @click="clickImg(index)"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pics: {
      type: Array,
      required: true,
    },
    cover: {
      type: String,
      default: "",
    },
    cols: {
      type: Number,
      default: 2,
      required: true,
    },
  },
  computed: {
    layoutClass() {
      return this.cols === 2 ? "two-columns" : "one-column";
    },
  },
  methods: {
    clickImg(index) {
      console.log("Image clicked");
      //emit to parent
      ///if cols ==2, skup
      if (this.cols === 1) {
        return;
      }
      this.$emit("clickImg", index);
    },
  },
};
</script>

<style scoped>
.one-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.one-column img {
  margin-bottom: 2px;
}

.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.two-columns .image-wrapper img {
  margin-bottom: 0;
  cursor: pointer;
}
/* mobile smaller gap */
@media (max-width: 800px) {
  .two-columns {
    gap: 2px;
  }
  .two-columns .image-wrapper img {
  }
}

.image-wrapper img {
  max-width: 100%;
  height: auto;
  display: block;
}

img {
  border-radius: 2px;
}
</style>
