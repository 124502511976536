var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default",null,null,{
      canFlipLeft: _vm.canFlipLeft,
      canFlipRight: _vm.canFlipRight,
      canZoomIn: _vm.canZoomIn,
      canZoomOut: _vm.canZoomOut,
      page: _vm.page,
      numPages: _vm.numPages,
      flipLeft: _vm.flipLeft,
      flipRight: _vm.flipRight,
      zoomIn: _vm.zoomIn,
      zoomOut: _vm.zoomOut,
    }),_c('div',{ref:"viewport",staticClass:"viewport",class:{
      zoom: _vm.zooming || _vm.zoom > 1,
      'drag-to-scroll': _vm.dragToScroll,
    },style:({ cursor: _vm.cursor == 'grabbing' ? 'grabbing' : 'auto' }),on:{"touchmove":_vm.onTouchMove,"pointermove":_vm.onPointerMove,"mousemove":_vm.onMouseMove,"touchend":_vm.onTouchEnd,"touchcancel":_vm.onTouchEnd,"pointerup":_vm.onPointerUp,"pointercancel":_vm.onPointerUp,"mouseup":_vm.onMouseUp,"wheel":_vm.onWheel}},[_c('div',{staticClass:"flipbook-container",style:({ transform: ("scale(" + _vm.zoom + ")") })},[_c('div',{staticClass:"click-to-flip left",style:({ cursor: _vm.canFlipLeft ? 'pointer' : 'auto' }),on:{"click":_vm.flipLeft}}),_c('div',{staticClass:"click-to-flip right",style:({ cursor: _vm.canFlipRight ? 'pointer' : 'auto' }),on:{"click":_vm.flipRight}}),_c('div',{style:({ transform: ("translateX(" + _vm.centerOffsetSmoothed + "px)") })},[(_vm.showLeftPage)?_c('img',{staticClass:"page fixed",style:({
            width: _vm.pageWidth + 'px',
            height: _vm.pageHeight + 'px',
            left: _vm.xMargin + 'px',
            top: _vm.yMargin + 'px',
          }),attrs:{"src":_vm.pageUrlLoading(_vm.leftPage, true)},on:{"load":function($event){return _vm.didLoadImage($event)}}}):_vm._e(),(_vm.showRightPage)?_c('img',{staticClass:"page fixed",style:({
            width: _vm.pageWidth + 'px',
            height: _vm.pageHeight + 'px',
            left: _vm.viewWidth / 2 + 'px',
            top: _vm.yMargin + 'px',
          }),attrs:{"src":_vm.pageUrlLoading(_vm.rightPage, true)},on:{"load":function($event){return _vm.didLoadImage($event)}}}):_vm._e(),_c('div',{style:({ opacity: _vm.flip.opacity })},_vm._l((_vm.polygonArray),function(ref){
            var key = ref[0];
            var bgImage = ref[1];
            var lighting = ref[2];
            var bgPos = ref[3];
            var transform = ref[4];
            var z = ref[5];
return _c('div',{key:key,staticClass:"polygon",class:{ blank: !bgImage },style:({
              backgroundImage: bgImage && ("url(" + (_vm.loadImage(bgImage)) + ")"),
              backgroundSize: _vm.polygonBgSize,
              backgroundPosition: bgPos,
              width: _vm.polygonWidth,
              height: _vm.polygonHeight,
              transform: transform,
              zIndex: z,
            })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(lighting.length),expression:"lighting.length"}],staticClass:"lighting",style:({ backgroundImage: lighting })})])}),0),_c('div',{staticClass:"bounding-box",style:({
            left: _vm.boundingLeft + 'px',
            top: _vm.yMargin + 'px',
            width: _vm.boundingRight - _vm.boundingLeft + 'px',
            height: _vm.pageHeight + 'px',
            cursor: _vm.cursor,
          }),on:{"touchstart":_vm.onTouchStart,"pointerdown":_vm.onPointerDown,"mousedown":_vm.onMouseDown}})])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }