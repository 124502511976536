<template>
    <div class="about-container">
      <div class="header">
        <img
          src="/brand/neff200line.png"
          alt="Logo"
          class="logo"
          @click="goHome"
        />
        <h1>About</h1>
      </div>
      <div class="content">
        <p>
          For the past six years, I have been incredibly fortunate to travel the world and explore different cultures, landscapes, and experiences.
          Armed with my camera and plenty of time to wander the streets, I try to capture moments that reveal the beauty and complexity of the human experience.
        </p>
        <p>
          Although some recurring themes and ideas emerge, narratives are not forced upon the viewer. These candid photographs are an invitation for you to join me on a journey of exploration, allowing you to appreciate the world and its people in a new way, just as I have.
        </p>
        <p>
          Feel free to <a href="mailto:hi@noentranceforforeigners.com">email me</a> if you'd like to:
          <ul>
            <li>inquire about prints</li>
            <li>help identify portrait subjects</li>
            <li>commission work</li>
            <li>book & exhibition opportunities</li>
          </ul>
        </p>
        <img src="/img/sign.jpg" alt="Sign Image" class="sign-image"/>
        <p class="credit">
          The title of this project has been inspired by this delicately painted, and explicit sign located in Kathmandu Durbar Square.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      goHome() {
        this.$router.push('/');
      }
    }
  }
  </script>
  
  <style scoped>
  .about-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    color: #333;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 5px;
    text-align: left;
  }
  
  .header img.logo {
    height: 50px;
    cursor: pointer;
  }
  
  .header h1 {
    margin-top: 0;
  }
  
  .content p {
    line-height: 1.6;
  }
  
  .content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .content ul li {
    margin-bottom: 5px;
  }
  
  .content a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .content a:hover {
    text-decoration: underline;
  }
  
  .sign-image {
    max-width: 100%;
    display: block;
    margin-top: 20px;
  }
  
  .credit {
    font-size: 0.9em;
    color: #666;
    margin-top: 20px;
  }
  </style>