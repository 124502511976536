<template>
  <div class="flipbook-container">
    <Flipbook
      class="flipbook"
      :flipDuration="1000"
      :pages="pages"
      :startPage="pageNum"
      :clickToZoom="true"
      :zooms="null"
      :centering="false"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
    >
      <div class="action-bar">
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
        />

        <!--  
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
        />-->
        <span class="page-num">
          Page {{ flipbook.page }} of {{ flipbook.numPages }}
        </span>
        <!-- 
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
        />-->
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
        />
      </div>
    </Flipbook>
  </div>
</template>

<script>
import Flipbook from "./Flipbook";
import LeftIcon from "vue-material-design-icons/ChevronLeftCircle.vue";
import RightIcon from "vue-material-design-icons/ChevronRightCircle.vue";

export default {
  components: {
    Flipbook,
    LeftIcon,
    RightIcon,
  },
  props: {
    pages: Array,
    pageNum: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onFlipLeftStart(page) {
      console.log("Flipping left from page", page);
    },
    onFlipLeftEnd(page) {
      this.$emit("update:pageNum", page);
      this.updateHash(page);
    },
    onFlipRightStart(page) {
      console.log("Flipping right from page", page);
    },
    onFlipRightEnd(page) {
      this.$emit("update:pageNum", page);
      this.updateHash(page);
    },
    onZoomStart: function(zoom) {
      return console.log("zoom-start", zoom);
    },
    onZoomEnd: function(zoom) {
      return console.log("zoom-end", zoom);
    },
    updateHash(pageNum) {
      window.location.hash = "#" + pageNum;
    },
  },
};
</script>

<style scoped>
.flipbook-container {
  position: relative;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  display: none;
  width: 100%;
  max-height: 90vh;
}

.page.current {
  display: block;
}

.action-bar {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.btn {
  cursor: pointer;
  font-size: 24px;
}

.page-num {
  font-size: 16px;
}

.flipbook {
  width: 95vw;
  height: 95vh;
}

.flipbook .viewport {
  width: 95vw;
  height: calc(100vh - 50px - 40px);
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #333;
}
</style>
